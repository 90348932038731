import 'react-toastify/dist/ReactToastify.min.css';
import '../index.css';

import {
  Navigate,
  Outlet,
  Params,
  Route,
  Routes,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router';
import { FC, useEffect, useState } from 'react';

import {
  AGENTS_NAVIGATION_EVENT,
  CALL_PERSONA_ROUTE,
  CREATE_PERSONA_ROUTE,
  EDIT_PERSONA_ROUTE,
  EMBED_ROUTE,
  ENVIRONMENT,
  EVENT2_ROUTE,
  EVENT_ROUTE,
  IAvatar,
  Loader,
  MAIN_ROUTE,
  STUDIO_NAVIGATION_EVENT,
  logger,
  useQueryParams
} from '../shared';

import { personaStore } from 'entities/persona';
import { avatarStore } from 'entities/avatar';

import { EmbedModal } from 'features/embed-persona';

import { UploadCustomPhoto } from 'widgets/upload-custom-photo/ui/UploadCustomPhoto';
import { ShareModal } from 'widgets/share-modal';

import { CreatePersonaPage } from 'pages/create-persona';
import { Dashboard } from 'pages/dashboard';
import { EditPersona } from 'pages/edit-persona';
import { Call } from 'pages/call';
import { CallEmbed } from 'pages/embed/ui/CallEmbed';
import { ToastContainer } from 'react-toastify';
import { HiOutlineX } from 'react-icons/hi';
import { useAuthStore } from 'stores/useAuthStore';
import useVoiceStore from 'stores/useVoiceStore';
import AuthModal from 'components/Modals/AuthModal';
import { creditStore, features } from 'entities/credit';
import { videoCallStore } from 'features/livekit-call';
import { userStore } from 'entities/user';
import { CallEvent, CallEvent2 } from 'pages/event';

export type AppProps = {
  updateCallStore: (key: string, value: any) => void;
  toggleUpgradeModal: (isOpened: boolean) => void;
  toggleBuyCreditsModal: (isOpened: boolean) => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  toggleUploadAvatar: () => void;
  toggleCreateAccModal: () => void;
  talkingPhotos: IAvatar[];
  customTalkingPhotos: IAvatar[];
  isChatAvailable: boolean;
  premiumVoicesAllowed: boolean;
  apiKey: string;
  agentKey: string;
  productName: string;
};

const AuthLoading = (props: AppProps) => {
  const { isLoading } = userStore();
  const { updateAuthStore } = useAuthStore();
  const {
    fetchAvatars,
    updateAvatarStore,
    setFilteredTalkingPhotos,
    setFilteredCustomTalkingPhotos,
    customTalkingPhotos
  } = avatarStore();
  const { fetchVoices } = useVoiceStore();
  const { fetchPersonas } = personaStore();
  const loc = useLocation();

  useEffect(() => {
    fetchAvatars();
    fetchPersonas();
    fetchVoices();
  }, []);

  // * Pass props from the main app

  const { updateCreditStore, toggleFeatureBlockModal } = creditStore();

  useEffect(() => {
    updateCreditStore('toggleBuyCreditsModal', () =>
      props.toggleBuyCreditsModal(true)
    );
    updateCreditStore('toggleUpgradeModal', () =>
      props.toggleUpgradeModal(true)
    );
    updateCreditStore(
      'toggleFeatureBlockModal',
      (open: boolean | typeof features[number]) =>
        props.toggleFeatureBlockModal(open)
    );
    updateAvatarStore('toggleUploadAvatar', () => props.toggleUploadAvatar());

    updateAuthStore('toggleCreateAccModal', () => props.toggleCreateAccModal());
  }, []);

  useEffect(() => {
    updateCreditStore('isChatAvailable', props.isChatAvailable);
  }, [props.isChatAvailable]);

  useEffect(() => {
    updateCreditStore('productName', props.productName);
  }, [props.productName]);

  useEffect(() => {
    updateCreditStore('premiumVoicesAllowed', props.premiumVoicesAllowed);
  }, [props.premiumVoicesAllowed]);

  useEffect(() => {
    updateAvatarStore('talkingPhotos', props.talkingPhotos);
    // setFilteredTalkingPhotos(); // TODO: Fix filtered photos
  }, [props.talkingPhotos]);

  useEffect(() => {
    if (customTalkingPhotos && customTalkingPhotos.length > 0) {
      fetchAvatars();
    }
  }, [props.customTalkingPhotos]);

  useEffect(() => {
    updateAuthStore('apiKey', props.apiKey);
  }, [props.apiKey]);

  useEffect(() => {
    updateAuthStore('agentKey', props.agentKey);
  }, [props.agentKey]);

  // * Update call menu functions in studio app

  const { persona, setMessages } = videoCallStore();
  const { togglePersonaEmbedModal, setPersonaShareModal } = personaStore();

  const agentsParams = useParams();

  useEffect(() => {
    if (persona && persona.id === agentsParams.id) {
      props.updateCallStore('shareAgent', () => setPersonaShareModal(persona));

      props.updateCallStore('embedAgent', () => {
        if (
          props.productName.toLowerCase() === 'plus' ||
          props.productName.toLowerCase() === 'premium'
        ) {
          togglePersonaEmbedModal(persona.id);
        } else {
          toggleFeatureBlockModal('embed');
        }
      });

      props.updateCallStore('startNewChat', () => setMessages([]));
    }
  }, [persona]);

  // * Handle routing communication with studio

  /**
   * AuthLoading component is responsible for handling the initial loading and setup of the application.
   * It fetches avatars, personas, and voices, and updates the necessary stores.
   * It also handles routing communication with the studio app.
   *
   * @param {AppProps} props - The props for the AuthLoading component.
   * @returns {JSX.Element} The rendered AuthLoading component.
   */

  const agentsLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Main App (Studio) Navigation event handler.
     * It is used as a callback for STUDIO_NAVIGATION_EVENT listener
     * to syncronize routing between apps:
     * When the location is changed in studio, agents updates router state,
     * so they are on the same page (pun intended).
     *
     * @param {Event} event - JS Event.
     */
    function studioNavigationHandler(event: Event) {
      const { location, params } = (
        event as CustomEvent<{
          location: Location;
          params: Readonly<Params<string>>;
        }>
      ).detail;

      if (
        location.pathname === agentsLocation.pathname &&
        location.pathname !== MAIN_ROUTE
      ) {
        return;
      }

      navigate(location.pathname + location.search);
    }

    window.addEventListener(STUDIO_NAVIGATION_EVENT, studioNavigationHandler);

    return () => {
      window.removeEventListener(
        STUDIO_NAVIGATION_EVENT,
        studioNavigationHandler
      );
    };
  }, [agentsLocation, agentsParams]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(AGENTS_NAVIGATION_EVENT, {
        detail: { location: agentsLocation, params: agentsParams }
      })
    );
  }, [agentsLocation, agentsParams, agentsParams.id]);

  if (loc.pathname.includes('embed')) {
    return <CallEmbed />;
  }

  if (isLoading)
    return (
      <div className="ag-w-full ag-h-screen ag-bg-white ag-flex ag-items-center ag-justify-center">
        <Loader size={96} />
      </div>
    );

  if (loc.pathname.includes('call') && loc.search.includes('shared')) {
    return <Call />;
  }

  return <Outlet />;
};

const App: FC<AppProps> = (props) => {
  const { me } = userStore();

  useEffect(() => {
    me();
  }, []);

  return (
    <>
      <EmbedModal />
      <ShareModal />
      <AuthModal />
      <UploadCustomPhoto />
      <Routes>
        <Route element={<AuthLoading {...props} />}>
          <Route path={MAIN_ROUTE} element={<Dashboard />} />
          <Route path={EDIT_PERSONA_ROUTE} element={<EditPersona />} />
          <Route path={CALL_PERSONA_ROUTE} element={<Call />} />
          <Route path={CREATE_PERSONA_ROUTE} element={<CreatePersonaPage />} />
          <Route path={EMBED_ROUTE} element={<CallEmbed />} />
          <Route path={EVENT_ROUTE} element={<CallEvent />} />
          <Route path={EVENT2_ROUTE} element={<CallEvent2 />} />
          <Route path="*" element={<Navigate to={MAIN_ROUTE} />} />
        </Route>
      </Routes>
      <ToastContainer
        closeButton={({ closeToast }) => (
          <button type="button" onClick={closeToast}>
            <HiOutlineX
              className="ag-size-5 ag-text-neutral-400 hover:ag-text-neutral-500"
              aria-hidden="true"
            />
          </button>
        )}
        hideProgressBar
      />
    </>
  );
};

export default App;
