import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { getCookie, setCookie } from '../lib/cookie';
import { useAuthStore } from '../../stores/useAuthStore';
import { ENVIRONMENT } from '..';

export const EXPIRED_SESSION_ERROR = 'EXPIRED_SESSION_ERROR';

export const httpService = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_NEW,
  timeout: 60000
});

httpService.interceptors.request.use(
  (config) => {
    const token = getCookie(`${ENVIRONMENT}_yepic_access_token`);

    if (token) {
      config.headers.Authorization =
        config.headers['x-api-key'] || config.headers['x-agent-key']
          ? ''
          : `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// let refreshTokenPromise: Promise<void> | null = null; // avoid multiple refresh

// httpService.interceptors.response.use(
//   (res) => res,
//   async (error) => {
//     const originalRequest: InternalAxiosRequestConfig & { _retry: boolean } =
//       error.config;
//     if (error instanceof AxiosError) {
//       if (originalRequest._retry) {
//         setCookie(`${ENVIRONMENT}_yepic_access_token`, '', 0);
//         setCookie(`${ENVIRONMENT}_yepic_refresh_token`, '', 0);
//         useAuthStore.setState({
//           user: null
//         });
//         return Promise.reject(new AxiosError(EXPIRED_SESSION_ERROR));
//       } else if (error.response?.status === 401) {
//         originalRequest._retry = true;
//         if (!refreshTokenPromise)
//           refreshTokenPromise = useAuthStore
//             .getState()
//             .refreshToken()
//             .finally(() => {
//               refreshTokenPromise = null;
//             });
//         await refreshTokenPromise;
//         const token = getCookie(`${ENVIRONMENT}_yepic_access_token`);
//         if (!token) return Promise.reject(error);
//         originalRequest.headers.Authorization = `Bearer ${token}`;
//         return axios(originalRequest);
//       }

//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   }
// );
